import React from 'react';
import styled from 'styled-components';
import {message, Button, Row, Col} from 'antd';
import {navigate} from 'gatsby';
import {useOutlet} from 'reconnect.js';
import qs from 'query-string';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import Form from 'rev.sdk.js/Generic/Form';

function CategoryDetail(props) {
  const {id, display} = qs.parse(props.location.search);
  const [loading, setLoading] = useOutlet('loading');
  const [token] = useOutlet('jwtToken');
  const [category, setCategory] = React.useState(null);

  const fetchRecordById = React.useCallback(
    async (id) => {
      setLoading(true);
      try {
        let resp = await JStorage.fetchOneDocument('categories', {id});
        setCategory(resp);
      } catch (err) {
        message.error('發生錯誤，請稍後再試。');
        console.warn('err', err);
      } finally {
        setLoading(false);
      }
    },
    [setLoading],
  );

  const updateRecordById = React.useCallback(
    async (id, formData, token) => {
      setLoading(true);
      try {
        let resp = await JStorage.updateDocument('categories', {id}, formData, {
          token,
        });
        message.success(
          '更新成功。若需更新前台，請回到列表點擊「更新前台」按鈕',
        );
      } catch (err) {
        message.error('更新錯誤，請稍後再試。');
        console.warn('err', err);
      } finally {
        setLoading(false);
      }
    },
    [setLoading],
  );

  React.useEffect(() => {
    if (id && !category) {
      fetchRecordById(id);
    }
  }, [id, category, fetchRecordById]);

  return (
    <Wrapper>
      <div className="title-container">
        <Button onClick={() => navigate('/categories')}>返回列表</Button>
        <h1>{display}分類詳情</h1>
      </div>

      {category && (
        <Form
          instance={category}
          schema={getFormSpec(display).schema}
          uiSchema={getFormSpec(display).uiSchema}
          onSubmit={async (formData, extValues = {}) => {
            let nextFormData = {children: formData.children};

            if (display === '全部商品') {
              nextFormData = {
                children: nextFormData.children.map((child) => ({
                  ...child,
                  name: child.display,
                  children: [],
                })),
              };
            }

            if (display === 'FAQ') {
              nextFormData = {
                children: nextFormData.children.map((child) => ({
                  ...child,
                  name: child.display,
                  children: child.children.map((c) => ({
                    ...c,
                    name: c.display,
                  })),
                })),
              };
            }

            await updateRecordById(formData.id, nextFormData, token);
          }}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f5f5f5;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > .title-container {
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    & > h1 {
      margin-left: 10px;
      font-size: 32px;
    }
  }
`;

const getFormSpec = (display) => {
  return {
    schema: {
      title: '',
      type: 'object',
      properties:
        display === '文章' || display === 'works' || display === 'hashtags'
          ? {
              children: {
                type: 'array',
                title: '',
                items: {type: 'string'},
              },
            }
          : display === '全部商品'
          ? {
              children: {
                type: 'array',
                title: '',
                items: {
                  type: 'object',
                  title: '',
                  properties: {
                    display: {
                      type: 'string',
                      title: '顯示名稱',
                    },
                  },
                },
              },
            }
          : display === 'FAQ' && {
              children: {
                type: 'array',
                title: '',
                items: {
                  type: 'object',
                  title: '',
                  properties: {
                    display: {
                      type: 'string',
                      title: '顯示名稱',
                    },
                    children: {
                      type: 'array',
                      title: '',
                      items: {
                        type: 'object',
                        properties: {
                          display: {
                            type: 'string',
                            title: '子顯示名稱',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
    },
    uiSchema: {},
  };
};

export default CategoryDetail;
